import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { edgedSlots, timeSlots } from "../../../data/data";
import AppointmentCard from "../../reuseable/cards/AppointmentCard";
const DateTimePicker = ({
  disabledDayIndexes,
  startDate,
  setStartDate,
  index,
  timeValue,
  setTimeValue,
  outerTimeStamps,
  setOuterTimeStamps,
}) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const day = startDate.getDay() - 1;
  // useEffect(() => {
  //   let arrAm = [];
  //   let arrPm = [];
  //   user?.shopId?.openingHours.map((item) => {
  //     let element = item;
  //     let findTimeFromIndx = arrAm.findIndex((ii) => ii == element.from);
  //     let findTimeTillIndx = arrPm.findIndex((ii) => ii == element.till);
  //     if (findTimeFromIndx < 0) {
  //       if (element.from) {
  //         arrAm.push(element.from);
  //       }
  //     }
  //     if (findTimeTillIndx < 0) {
  //       if (element.till) {
  //         arrPm.push(element.till);
  //       }
  //     }
  //     setTimeSlots([...arrAm.sort(), ...arrPm.sort()]);
  //   });
  // }, []);
  useEffect(() => {
    if (user?.shopId) {
      if (
        user?.shopId?.openingHours?.length &&
        user?.shopId?.openingHours[day]
      ) {
        let from = user?.shopId?.openingHours[day].from.substr(0, 2);
        let end = Number(user?.shopId?.openingHours[day].till.substr(0, 2));
        let endArr = [];
        let fromArr = [];
        if (user?.shopId?.openingHours[day].from) {
          for (var i = Number(from); i <= 12; i++) {
            if (i === 12) {
              fromArr.push(`${i}:30 pm`);
            } else {
              fromArr.push(`${i}:30 am`);
            }
          }
        }
        if (user?.shopId?.openingHours[day].till) {
          for (var i = end; i > 0; i--) {
            endArr.push(`${i}:30 pm`);
          }
        }

        // filteredSlots.current =
        endArr.shift();
        setTimeSlots(fromArr.concat(endArr.reverse()));
      } else {
        setTimeSlots([]);
        // filteredSlots.current = []
      }
    }
  }, [day]);
  // console.log(filteredSlots,'<==filteredSlots')
  const isWeekday = (date) => {
    const day = date.getDay();
    if (day === 0) return false;
    else if (disabledDayIndexes.includes(day)) return false;
    else return true;
  };

  const handleTimeStamps = (time) => {
    setTimeValue(time);
    setOuterTimeStamps({
      ...outerTimeStamps,
      isAfterHours: false,
      isEarlyBird: false,
    });
  };
  const handleOuterTimeStamps = (key) => {
    if (key) {
      setOuterTimeStamps({
        ...outerTimeStamps,
        isAfterHours: true,
        isEarlyBird: false,
      });
    } else {
      setOuterTimeStamps({
        ...outerTimeStamps,
        isEarlyBird: true,
        isAfterHours: false,
      });
    }
  };
  return (
    <AppointmentCard>
      <Typography
        mb="1em"
        fontWeight={"bold"}
      >{`${index}. Select Date and Time`}</Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
      >
        {/* Date Piicker */}
        <Stack width={{ xs: "100%", md: "48%" }}>
          <Typography variant="body1" paddingBottom={2}>
            Select Date
          </Typography>
          <DatePicker
            selected={startDate}
            minDate={new Date()}
            onChange={(date) => setStartDate(date)}
            inline
            filterDate={isWeekday}
            fixedHeight
          />
        </Stack>

        {/* Time Picker  */}
        <Stack width={{ xs: "100%", md: "48%" }} spacing={2}>
          <Typography variant="body1" paddingBottom={2}>
            Select Time
          </Typography>
          <Grid container paddingBottom={2} mt={"0 !important"}>
            {timeSlots.map((time, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  md={3}
                  key={index}
                  pr={"1em"}
                  pb={"1em"}
                  onClick={() => handleTimeStamps(time)}
                >
                  <Stack
                    borderRadius={1}
                    fontSize={"0.8em"}
                    className={timeValue === time ? "styledTime" : ""}
                    bgcolor={"#FFFFFF"}
                    border={"1px solid rgba(0, 0, 0, 0.12)"}
                    paddingX={{ xs: 0, md: 2 }}
                    paddingY={2}
                    textAlign={"center"}
                  >
                    {index === 0 && user?.shopId?.openingHours?.length > 0
                      ? user?.shopId?.openingHours[day].from
                      : time}
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <Grid container>
            {edgedSlots.map((time, key) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  pr={"1em"}
                  pb={"1em"}
                  onClick={() => {
                    setTimeValue(time.time);
                    handleOuterTimeStamps(key);
                  }}
                >
                  <Stack
                    borderRadius={1}
                    className={timeValue === time.time ? "styledTime" : ""}
                    bgcolor={"#F2F4F7"}
                    border={"1px solid #D0D5DD"}
                    paddingX={2}
                    paddingY={2}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <img src={time.img} alt="" />
                      <Typography variant="body2">{time.label}</Typography>
                    </Stack>
                    <Typography variant="caption" color={"#98A2B3"}>
                      {time.value}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </AppointmentCard>
  );
};

export default DateTimePicker;
